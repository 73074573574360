import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Category from './category';
import NumberHelper from 'util/number_helper';
import withFeatureFlags from 'components/Flipper/withFeatureFlags';

const LabelCategory = forwardRef(({ category, featureFlags, onClick }, ref) => {
  const style = {
    backgroundColor: category.color
  };

  const guidelinesEnabled = featureFlags.includes('guidelines');
  const categoryKey = guidelinesEnabled && category.name === 'grey' ? 'grey_new' : category.name;

  return (
    <button className="label" onClick={onClick} ref={ref}>
      <div className="title">
        <div className="swatch" style={style} />
        <span className="text">
          {I18n.t(categoryKey, {
            scope: 'Explorer.Highlights.Breakdown.categories'
          })}
        </span>
      </div>

      <div className="count">
        {I18n.t('Explorer.Highlights.Breakdown.mentions', {
          count: category.mentionCount,
          formattedCount: NumberHelper.formatNumberWithDelimiter(category.mentionCount)
        })}
      </div>
    </button>
  );
});

LabelCategory.displayName = 'LabelCategory';

LabelCategory.propTypes = {
  category: PropTypes.instanceOf(Category).isRequired,
  featureFlags: PropTypes.array,
  onClick: PropTypes.func.isRequired
};

export default withFeatureFlags(LabelCategory);
