import React from 'react';
import PropTypes from 'prop-types';
import { concat, includes, uniq, without } from 'lodash-es';
import config from 'util/site_config.js.erb';

import FilterSet from 'components/Explorer/models/filter_set';
import HelpLink from '../HelpLink';

const OUTPUT_TYPES = config.outputTypes || [];

class TypeFilter extends React.Component {
  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    analyticsFieldName: PropTypes.string
  };

  render() {
    return (
      <div className="Explorer-AdvancedSearch-field TypeFilter">
        {this.label}
        {this.options}
      </div>
    );
  }

  get label() {
    return (
      <label>
        {I18n.t('Explorer.AdvancedSearch.fields.type.title')}
        <HelpLink
          href={I18n.t('Explorer.AdvancedSearch.help_uri')}
          tip={I18n.t('Explorer.AdvancedSearch.fields.type.description')}
          analyticsFieldName="Type Of Output"
        />
      </label>
    );
  }

  get options() {
    return (
      <fieldset>
        <legend>{I18n.t('Explorer.AdvancedSearch.fields.type.title')}</legend>
        <label className={`select-all ${this.noneSelected ? 'selected' : 'not-selected'}`}>
          <input
            id="AdvancedSearch-Type-Filter-all"
            type="checkbox"
            checked={this.noneSelected}
            onChange={this.onSelectAll}
          />
          <span className="label">{I18n.t('Explorer.AdvancedSearch.fields.type.types.all')}</span>
        </label>
        <div className="others">{OUTPUT_TYPES.map((type) => this.typeField(type))}</div>
      </fieldset>
    );
  }

  typeField(type) {
    const unavailable = !window.current_user.books && (type === 'book' || type === 'chapter');
    const id = `AdvancedSearch-Type-Filter-${type}`;
    const selected = !unavailable && this.isSelected(type);

    const classNames = [];
    if (unavailable) classNames.push('unavailable');
    if (selected) classNames.push('selected');

    return (
      <div key={type}>
        <label htmlFor={id} className={classNames.join(' ')}>
          <input
            disabled={unavailable}
            id={id}
            type="checkbox"
            checked={selected}
            onChange={(e) => this.onSelectType(type, e)}
          />
          <span className="label">
            <span className="text">
              {I18n.t(type, {
                scope: 'Explorer.AdvancedSearch.fields.type.types'
              })}
            </span>
            {unavailable ? (
              <span
                className="warning"
                data-tooltip-content={I18n.t('Explorer.AdvancedSearch.fields.type.unavailable')}
                data-tooltip-id="HelpLink"
              />
            ) : null}
          </span>
        </label>
      </div>
    );
  }

  get value() {
    return this.props.filterSet.get('type[]') || [];
  }

  isSelected(type) {
    return includes(this.value, type);
  }

  get noneSelected() {
    return this.value.length === 0;
  }

  onSelectAll = () => {
    this.selectNone();
    this.trackEvent('Item Selected', 'all');
  };

  selectNone = () => {
    this.setSelected([]);
  };

  onSelectType = (type, e) => {
    const isSelected = e.target.checked;
    let newTypes = [];

    if (isSelected) {
      newTypes = uniq(concat(this.value, type));
      this.trackEvent('Item Selected', type);
    } else {
      newTypes = without(this.value, type);
      this.trackEvent('Item Deselected', type);
    }

    if (newTypes.length == config.outputTypes.length) {
      newTypes = [];
    }

    this.setSelected(newTypes);
  };

  setSelected = (types) => {
    this.props.filterSet.set('type[]', types);
    this.forceUpdate();
  };

  trackEvent(eventName, value = null) {
    if (!this.trackingEnabled) return;

    Analytics.trackEvent(`Advanced Search: Field ${eventName}`, {
      field_name: this.props.analyticsFieldName,
      ...(value && { value: value })
    });
  }

  get trackingEnabled() {
    return !!this.props.analyticsFieldName;
  }
}

export default TypeFilter;
