import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import FilterSet from 'components/Explorer/models/filter_set';
import InputField from './InputField';

const PublisherFilter = ({ filterSet, history, initialSearch }) => (
  <InputField
    filterSet={filterSet}
    history={history}
    initialSearch={initialSearch}
    name="publisher_id"
    id="filter_publisher_id"
    type="publishers"
    label={I18n.t('Explorer.AdvancedSearch.fields.publishers.name')}
    helpUrl={I18n.t('Explorer.AdvancedSearch.help_uri')}
    helpTip={I18n.t('Explorer.AdvancedSearch.fields.publishers.help')}
    analyticsEventPrefix="Advanced Search: Field"
    analyticsFieldName="Publisher Name"
    loadingMessage={I18n.t('Explorer.AdvancedSearch.fields.publishers.loading')}
    placeholder={I18n.t('Explorer.AdvancedSearch.fields.publishers.placeholder')}
    queryMethod="publisherNames"
  />
);

PublisherFilter.propTypes = {
  filterSet: PropTypes.instanceOf(FilterSet).isRequired,
  history: PropTypes.object,
  initialSearch: PropTypes.string
};

export default withRouter(PublisherFilter);
