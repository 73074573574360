import React from 'react';
import useFeatureFlags from './useFeatureFlags';

const withFeatureFlags = (WrappedComponent) => {
  const ComponentWithFeatureFlags = (props, ref) => {
    const featureFlags = useFeatureFlags();
    return <WrappedComponent featureFlags={featureFlags} ref={ref} {...props} />;
  };

  return React.forwardRef(ComponentWithFeatureFlags);
};

export default withFeatureFlags;
