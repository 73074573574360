import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import FeatureFlagContext from './FeatureFlagContext';
import Query from 'components/Explorer/models/query';
import fetchJSON from './fetchJSON';

const FeatureFlagProvider = ({ children }) => {
  const controller = new AbortController();
  const location = useLocation();
  const [featureFlags, setFeatureFlags] = useState([]);

  useEffect(() => {
    const updateFeatureFlags = async () => {
      const newFeatureFlags = await fetchJSON(`${Query.BASE_JSON_PATH}/features`, { signal: controller.signal });

      if (!Array.isArray(newFeatureFlags)) {
        throw new Error(`Bad response from Flipper: ${JSON.stringify(newFeatureFlags)}`);
      }

      setFeatureFlags(newFeatureFlags);
    };

    updateFeatureFlags();

    return () => controller.abort();
  }, [location]);

  return <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>;
};

export default FeatureFlagProvider;
