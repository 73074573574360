import React from 'react';
import PropTypes from 'prop-types';

import FilterSet from 'components/Explorer/models/filter_set';
import InputField from './InputField';

const AuthorFilter = ({ filterSet, initialSearch }) => (
  <InputField
    filterSet={filterSet}
    initialSearch={initialSearch}
    name="author_id"
    id="filter_author_id"
    type="authors"
    label={I18n.t('Explorer.AdvancedSearch.fields.authors.title')}
    helpUrl={I18n.t('Explorer.AdvancedSearch.help_uri')}
    helpTip={I18n.t('Explorer.AdvancedSearch.fields.authors.description')}
    analyticsEventPrefix="Advanced Search: Field"
    analyticsFieldName="Verified Author"
    loadingMessage={I18n.t('Explorer.AdvancedSearch.fields.authors.loading')}
    placeholder={I18n.t('Explorer.AdvancedSearch.fields.authors.placeholder')}
    queryMethod="authorNames"
  />
);

AuthorFilter.propTypes = {
  filterSet: PropTypes.instanceOf(FilterSet).isRequired,
  initialSearch: PropTypes.string
};

export default AuthorFilter;
