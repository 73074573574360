import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import NumberHelper from 'util/number_helper';

import InspectorSource from './InspectorSource';

const InspectorCategory = ({
  categoryName,
  categoryMentionCount,
  categorySources = [],
  totalMentions,
  onCloseInspector
}) => {
  const sourcesContainer = useRef(null);

  useEffect(() => {
    sourcesContainer.current.addEventListener('keydown', onKeyDown);
  }, []);

  function onKeyDown(e) {
    const sourceLinks = this.querySelectorAll('a');
    const firstSourceLink = sourceLinks[0];
    const lastSourceLink = sourceLinks[sourceLinks.length - 1];
    const isTabPressed = e.key === 'Tab';

    if (!isTabPressed) {
      return;
    }

    if (e.shiftKey) {
      // shift + tab
      if (e.target === firstSourceLink) {
        onCloseInspector();

        e.preventDefault();
      }
    } else {
      // tab
      if (e.target === lastSourceLink) {
        onCloseInspector();

        e.preventDefault();
      }
    }
  }

  const percentOfTotal = () => {
    let percent = categoryMentionCount / totalMentions;
    if (percent < 0.01) {
      percent = '< 1';
    } else {
      percent = Math.round(percent * 100);
    }

    return percent;
  };

  return (
    <div>
      <h3 className="title">
        <span className="name">{categoryName}</span>
        <span className="share">
          {I18n.t('Explorer.Highlights.Breakdown.category_percentage', {
            count: totalMentions,
            percentage: percentOfTotal(),
            formattedCount: NumberHelper.formatNumberWithDelimiter(totalMentions)
          })}
        </span>
      </h3>

      <div className="sources" ref={sourcesContainer}>
        {categorySources.map((source, index) =>
          source.mentionCount > 0 ? <InspectorSource key={source.name} source={source} isFocused={index === 0} /> : null
        )}
      </div>
    </div>
  );
};

InspectorCategory.propTypes = {
  categoryName: PropTypes.string.isRequired,
  categoryMentionCount: PropTypes.number.isRequired,
  categorySources: PropTypes.array,
  totalMentions: PropTypes.number.isRequired,
  onCloseInspector: PropTypes.func.isRequired
};

export default InspectorCategory;
