import React from 'react';
import PropTypes from 'prop-types';
import { concat, filter, flow, includes, sumBy, uniq } from 'lodash-es';

import NumberHelper from 'util/number_helper';

const Entry = ({ onClick, isActive, classes, children }) => (
  <li className="entry">
    <button onClick={onClick} className={`content ${classes}`} aria-pressed={isActive}>
      <div className="active-content">{children}</div>
      <div aria-hidden className="inactive-content">
        {children}
      </div>
    </button>
  </li>
);

class TimelineChartLegend extends React.Component {
  static propTypes = {
    entries: PropTypes.array.isRequired,
    selectedPostTypes: PropTypes.array.isRequired,
    updateSelectedPostTypes: PropTypes.func.isRequired
  };

  render() {
    const total = sumBy(this.props.entries, 'mention_count');
    const pluralisedMentions = (mentionCount) => {
      return I18n.t(
        mentionCount === 1
          ? 'Explorer.Timeline.chart.legend.mentions.one'
          : 'Explorer.Timeline.chart.legend.mentions.other'
      );
    };

    return (
      <section aria-labelledby="TimelineChartLegend-title">
        <h2 id="TimelineChartLegend-title" className="visually-hidden">
          {I18n.t('Explorer.Timeline.chart.legend.label')}
        </h2>
        <ul className="TimelineChartLegend">
          <Entry
            key="show-all"
            onClick={this.hideAllPostTypes}
            isActive={this.props.selectedPostTypes.length === 0}
            classes="show-all"
          >
            <div className="inner">
              <div className="name">{I18n.t('Explorer.Timeline.chart.all_types')}</div>
              <div className="mentions">
                {NumberHelper.formatNumberWithDelimiter(total)}{' '}
                <span className="visually-hidden">{pluralisedMentions(total)}</span>
              </div>
            </div>
          </Entry>
          {this.props.entries.map((entry) => {
            if (entry.mention_count === 0) return;

            return (
              <Entry
                key={entry.tab_id}
                onClick={(event) => this.didClickEntry(entry, event)}
                isActive={this.currentlySelected(entry.post_type)}
                classes={entry.tab_id}
              >
                <div className="inner">
                  <div className="name">{I18n.t(entry.post_type, { scope: 'Explorer.Timeline.chart.types' })}</div>
                  <div className="mentions">
                    {NumberHelper.formatNumberWithDelimiter(entry.mention_count)}
                    <span className="visually-hidden">{pluralisedMentions(entry.mention_count)}</span>
                  </div>
                </div>
              </Entry>
            );
          })}
        </ul>
      </section>
    );
  }

  currentlySelected(type) {
    return includes(this.props.selectedPostTypes, type);
  }

  didClickEntry = (entry, event) => {
    if (event.metaKey || event.ctrlKey) {
      this.appendOrToggleEntry(entry);
    } else {
      if (this.currentlySelected(entry.post_type) && this.props.selectedPostTypes.length === 1) {
        this.hideAllPostTypes();
      } else {
        this.showSinglePostType(entry.post_type);
      }
    }
  };

  appendOrToggleEntry = (entry) => {
    if (this.currentlySelected(entry.post_type)) {
      this.hidePostType(entry.post_type);
    } else {
      this.showPostType(entry.post_type);
    }
  };

  hideAllPostTypes = () => {
    this.setTypes([]);
  };

  hidePostType = (type) => {
    this.setTypes(flow((p) => filter(p, (f) => type !== f))(this.props.selectedPostTypes));
  };

  showPostType = (type) => {
    this.setTypes(flow((p) => concat(p, type), uniq)(this.props.selectedPostTypes));
  };

  showSinglePostType = (type) => {
    this.setTypes([type]);
  };

  setTypes = (types) => {
    Analytics.trackEvent('timeline-set-post-types', { types });
    this.props.updateSelectedPostTypes(types);
  };
}

export default TimelineChartLegend;
