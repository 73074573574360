import React from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash-es';

class AutocompleteFieldResults extends React.Component {
  static propTypes = {
    results: PropTypes.array,
    didScroll: PropTypes.func,
    currentlySelected: PropTypes.object,
    onSelectResult: PropTypes.func,
    type: PropTypes.string
  };

  componentDidUpdate(prevProps) {
    if (prevProps.currentlySelected !== this.props.currentlySelected) {
      this.updateScrollPosition();
    }
  }

  render() {
    return (
      <div className="AutocompleteFieldResults" data-testid="results">
        <div
          className="AutocompleteFieldResults-content"
          ref={(ref) => (this.contentWrapper = ref)}
          onScroll={this.props.didScroll}
        >
          {this.props.results.map(this.renderResult)}
        </div>
      </div>
    );
  }

  renderResult = (result) => {
    return (
      // eslint-disable-next-line -- jsx-a11y [TODO]
      <div
        key={result.id}
        ref={(el) => this.createRefForSelected(el, result)}
        className={this.resultClassName(result)}
        onClick={() => this.props.onSelectResult(result)}
      >
        {this.renderContent(result)}
      </div>
    );
  };

  createRefForSelected(el, result) {
    if (this.props.currentlySelected === result) {
      this.selectedRow = el;
    }
  }

  renderContent(result) {
    switch (this.props.type) {
      case 'publishers':
        return <GenericResult result={result} />;
      case 'doi_prefixes':
        return <GenericResult result={result} />;
      case 'authors':
        return <Author result={result} />;
      case 'departments':
        return <GenericResult result={result} />;
      case 'countries':
        return <GenericResult result={result} />;
      case 'mention_sources':
      case 'mention_sources_types':
        return <MentionSource result={result} />;
      case 'handle_prefixes':
        return <GenericResult>{result.id}</GenericResult>;
      case 'journals':
        return <Journal result={result} />;
      case 'affiliations':
        return <GenericResult result={result} />;
      case 'field_of_research_codes':
        return (
          <GenericResult>
            {result.id} {result.name}
          </GenericResult>
        );
      case 'funders':
        return <GenericResult result={result} />;
      case 'grants':
        return <Grant result={result} />;
      case 'sustainable_development_goals':
        return (
          <GenericResult>
            {result.id} {result.name}
          </GenericResult>
        );
    }
  }

  resultClassName(result) {
    const selected = this.props.currentlySelected === result;

    return `AutocompleteFieldResults-row ${selected ? 'selected' : ''}`;
  }

  updateScrollPosition() {
    if (!this.selectedRow) return;

    const rowTop = this.selectedRow.offsetTop;
    const rowHeight = this.selectedRow.offsetHeight;
    const currentScroll = this.contentWrapper.scrollTop;
    const wrapperHeight = this.contentWrapper.offsetHeight;

    if (rowTop + rowHeight - currentScroll > wrapperHeight) {
      const scrollAmount = rowTop + rowHeight - wrapperHeight;
      this.contentWrapper.scrollTop = scrollAmount;
    } else if (rowTop - currentScroll < 0) {
      this.contentWrapper.scrollTop = rowTop;
    }
  }
}

class Author extends React.Component {
  static propTypes = {
    result: PropTypes.object
  };

  render() {
    return (
      <div className="AutocompleteFieldResults-row-content">
        <span className="title"> {this.formattedName}</span>
        <span className="meta">{this.formattedDepartments}</span>
      </div>
    );
  }

  get formattedDepartments() {
    const totalCount = this.props.result.departments.length;
    const selected = this.props.result.departments.slice(0, 2);
    const str = selected.join(', ');

    if (selected.length < totalCount) {
      return I18n.t('Explorer.AdvancedSearch.fields.authors.more_departments', {
        departments: str,
        count: totalCount - selected.length
      });
    } else {
      return str;
    }
  }

  get formattedName() {
    const nameFields = [this.props.result.first_name, this.props.result.last_name];
    return compact(nameFields).join(' ');
  }
}

class Journal extends React.Component {
  static propTypes = {
    result: PropTypes.object
  };

  render() {
    return (
      <div className="AutocompleteFieldResults-row-content">
        <span className="title">{this.props.result.title}</span>
        {this.issns}
      </div>
    );
  }

  get issns() {
    if (!this.props.result.issns.length) return;

    return (
      <span className="meta">
        {I18n.t('Explorer.AdvancedSearch.fields.journal.issn_list', {
          issns: this.props.result.issns.join(', ')
        })}
      </span>
    );
  }
}

class MentionSource extends React.Component {
  static propTypes = {
    result: PropTypes.object
  };

  state = { imageError: false };

  render() {
    switch (this.props.result.type) {
      case 'mention_source':
        return this.renderMentionSource;
      case 'post_type':
      case 'mention_source_type':
        return this.renderPostType;
    }
  }

  get renderMentionSource() {
    return (
      <div className="AutocompleteFieldResults-row-content">
        <div className={`image ${this.state.imageError ? 'missing-image' : 'present-image'}`}>
          {/* eslint-disable-next-line -- jsx-a11y [TODO] */}
          <img src={this.props.result.imageURL} onError={this.imageDidError} />
        </div>
        <div className="content">
          <span className="meta">{this.props.result.title}</span>
          <span className="name">{this.props.result.description}</span>
        </div>
      </div>
    );
  }

  get renderPostType() {
    return (
      <div className="AutocompleteFieldResults-row-content">
        <div className={`image source-icon ${this.props.result.postType}`} />
        <div className="content">
          <span className="name">{this.props.result.title}</span>
          <span className="meta">{this.props.result.description}</span>
        </div>
      </div>
    );
  }

  imageDidError = (_e) => {
    this.setState({ imageError: true });
  };
}

const Grant = ({ result }) => {
  return (
    <div className="AutocompleteFieldResults-row-content">
      <span className="name">{result.name}</span>
      <span className="meta">{result.projectName}</span>
    </div>
  );
};

const GenericResult = ({ children, result }) => {
  return (
    <div className="AutocompleteFieldResults-row-content">
      <span className="title">{children || result.name}</span>
    </div>
  );
};

GenericResult.propTypes = {
  children: PropTypes.node,
  result: PropTypes.object
};

export default AutocompleteFieldResults;
