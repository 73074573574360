import React from 'react';
import PropTypes from 'prop-types';

import FilterSet from 'components/Explorer/models/filter_set';
import InputField from '../InputField';

const SustainableDevelopmentGoalsFilter = ({ filterSet, initialSearch }) => (
  <InputField
    filterSet={filterSet}
    initialSearch={initialSearch}
    name="sustainable_development_goals"
    id="sustainable_development_goals"
    type="sustainable_development_goals"
    label={I18n.t('Explorer.AdvancedSearch.fields.sustainable_development_goals.title')}
    helpUrl={I18n.t('Explorer.AdvancedSearch.help_uri')}
    helpTip={I18n.t('Explorer.AdvancedSearch.fields.sustainable_development_goals.help')}
    analyticsEventPrefix="Advanced Search: Field"
    analyticsFieldName="Sustainable Development Goals"
    loadingMessage={I18n.t('Explorer.AdvancedSearch.fields.sustainable_development_goals.loading')}
    placeholder={I18n.t('Explorer.AdvancedSearch.fields.sustainable_development_goals.placeholder')}
    queryMethod="sustainable_development_goals"
  />
);

SustainableDevelopmentGoalsFilter.propTypes = {
  filterSet: PropTypes.instanceOf(FilterSet).isRequired,
  initialSearch: PropTypes.string
};

export default SustainableDevelopmentGoalsFilter;
