import React from 'react';
import PropTypes from 'prop-types';

import FilterSet from 'components/Explorer/models/filter_set';
import InputField from './InputField';

const GrantsFilter = ({ filterSet, initialSearch }) => (
  <InputField
    filterSet={filterSet}
    initialSearch={initialSearch}
    name="grant_id"
    id="filter_grant_id"
    type="grants"
    label="Grant ID"
    helpUrl={I18n.t('Explorer.AdvancedSearch.help_uri')}
    helpTip="Enter one or more grant IDs."
    analyticsEventPrefix="Advanced Search: Field"
    analyticsFieldName="Grant ID"
    loadingMessage="Loading grants…"
    placeholder="Enter one or more grant IDs."
    queryMethod="grants"
  />
);

GrantsFilter.propTypes = {
  filterSet: PropTypes.instanceOf(FilterSet).isRequired,
  initialSearch: PropTypes.string
};

export default GrantsFilter;
