import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';
import FilterSet from 'components/Explorer/models/filter_set';

import AutocompleteField from '../AutocompleteField';
import HelpLink from './HelpLink';

class InputField extends React.Component {
  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    history: PropTypes.object,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    initialSearch: PropTypes.string,
    label: PropTypes.string.isRequired,
    helpUrl: PropTypes.string,
    helpTip: PropTypes.string,
    analyticsEventPrefix: PropTypes.string,
    analyticsFieldName: PropTypes.string,
    loadingMessage: PropTypes.string,
    placeholder: PropTypes.string,
    queryMethod: PropTypes.string
  };

  state = {
    loading: false,
    items: []
  };

  UNSAFE_componentWillMount() {
    if (!this.items.length) return;

    if (this.props.queryMethod) {
      this.query = new Query(this.props.history);
      this.fetchData();
    } else {
      this.setState({ items: this.items });
    }
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    if (this.query) this.query.cleanup();
  }

  componentDidUpdate() {
    if (!this.fieldRef) return;
    if (!this.items.length) this.fieldRef.clearAll();
  }

  render() {
    return (
      <div className="Explorer-AdvancedSearch-field">
        <label>
          {this.props.label}
          <HelpLink
            href={this.props.helpUrl}
            tip={this.props.helpTip}
            analyticsFieldName={this.props.analyticsFieldName}
          />
        </label>
        {this.state.loading ? (
          <div>{this.props.loadingMessage}</div>
        ) : (
          <AutocompleteField
            name={this.props.name}
            id={this.props.id}
            type={this.props.type}
            initialValue={this.props.initialSearch}
            placeholder={this.props.placeholder}
            onChangeItems={this.updateSelectedItems}
            initiallySelected={this.state.items}
            ref={this.getFieldRef}
            analyticsEventPrefix={this.props.analyticsEventPrefix}
            analyticsFieldName={this.props.analyticsFieldName}
          />
        )}
      </div>
    );
  }

  fetchData = () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query[this.props.queryMethod]();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      if (!err) {
        this.setState({ loading: false, items: response.body });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  updateSelectedItems = (items) => {
    this.props.filterSet.set(
      `${this.props.name}[]`,
      items.map((item) => item.id)
    );
  };

  get items() {
    return this.props.filterSet.get(`${this.props.name}[]`).map((id) => ({ id }));
  }

  getFieldRef = (elm) => {
    this.fieldRef = elm;
  };
}

export default withRouter(InputField);
