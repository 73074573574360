import { filter, flow, map, maxBy, reverse, slice, sortBy, sum, times, values } from 'lodash-es';
import Color from 'color';

// SegmentList takes a list of post counts and a number of segments to return.
//
// It uses the post counts to calculate how to break the list of post counts
// down into different segments for display in a donut or other visualisation,
// ensuring that all of the most common post types have at least one segment,
// then distributing the remaining space between the other post types to
// reflect the proportion of each.
//
// The eventual list is sorted by color and a list of Segment objects is returned.
class SegmentList {
  constructor(postCounts, segmentCount) {
    this.segmentCount = segmentCount;
    this.postCounts = postCounts;
  }

  get segments() {
    const selectedSources = this.selectedSources;
    const segments = selectedSources.map((s) => new Segment(s.type, s.width));

    // Sort the segments using a rather complicated colour difference algorithm
    // that initially came from the Altmetric API.
    return segments.sort((a, b) => {
      const colorA = a.sortColor.hsl().array();
      const colorB = b.sortColor.hsl().array();

      return colorA[0] - colorB[0] + colorA[1] - colorB[1] + colorA[2] - colorB[2];
    });
  }

  get selectedSources() {
    // Filter the list so that we only consider sources with attention
    const sourcesWithAttention = filter(this.sourcesWithPercentages, (s) => s.count > 0);

    // Try to get the full number of segments directly from the list of
    // percentages, ordered so we take the highest-scoring ones first.
    const selectedSources = flow(
      (s) => sortBy(s, 'count'),
      reverse,
      (s) => slice(s, 0, this.segmentCount)
    )(sourcesWithAttention);

    // If there are no sources of attention, return a default unknown category
    if (selectedSources.length === 0) return [{ type: 'unknown', width: this.segmentCount }];

    // If we found the desired number of segments, then we have everything we
    // need, so we can return the list.
    if (selectedSources.length === this.segmentCount) return selectedSources;

    // Otherwise, we need to increase the segment count for some sources to make
    // up the full number of requested segments. We do this by repeatedly finding
    // the type with the highest percentage of mentions per width unit, and
    // incrementing the width.
    const additionalSegmentCount = this.segmentCount - selectedSources.length;
    times(additionalSegmentCount, () => {
      const sourceWithHighestShare = maxBy(selectedSources, (s) => s.count / s.width);
      sourceWithHighestShare.width += 1;
    });

    return selectedSources;
  }

  get sourcesWithPercentages() {
    const totalPosts = this.totalPosts;

    return map(this.postCounts, (count, type) => {
      return {
        type: type,
        count: count,
        percentage: count / totalPosts,
        width: 1
      };
    });
  }

  get totalPosts() {
    return sum(values(this.postCounts));
  }
}

class Segment {
  static COLORS = {
    tweet: ['#74CFED', '#2F90B9'],
    blog: ['#ffd140', '#e89500'],
    fbwall: ['#2445bd', '#0b2ca4'],
    gplus: ['#E065BB', '#912470'],
    msm: ['#FF0000', '#B60000'],
    linkedin: ['#1E90FF', '#00BFFF'],
    rdt: ['#D5E8F0', '#B9DDEB'],
    f1000: ['#F4006E', '#CB2D2D'],
    pinterest: ['#cc6600', '#9c4e00'],
    qna: ['#DEDEDE', '#EFEFEF'],
    video: ['#94DB5E', '#98C973'],
    unknown: ['#DEDEDE', '#EFEFEF'],
    wikipedia: ['#958899', '#3b2a3d'],
    peer_review: ['#efefef', '#bdbdbd'],
    weibo: ['#e1a400', '#df931b'],
    policy: ['#9f79f2', '#5b17e8'],
    book_review: ['#5fb441', '#33741c'],
    syllabus: ['#a6e8bc', '#7cad8d'],
    patent: ['#f27700', '#d45029'],
    guideline: ['#A1E3E4', '#15BABC'],
    bluesky: ['#70B1FF', '#1185FE']
  };

  constructor(type, width) {
    this.type = type;
    this.width = width;
  }

  get darkColor() {
    return Segment.COLORS[this.type][1];
  }

  get lightColor() {
    return Segment.COLORS[this.type][0];
  }

  get sortColor() {
    return Color(this.lightColor);
  }
}
export default SegmentList;
