import React from 'react';
import PropTypes from 'prop-types';

import FilterSet from 'components/Explorer/models/filter_set';
import InputField from './InputField';

const DepartmentFilter = ({ filterSet, initialSearch }) => (
  <InputField
    filterSet={filterSet}
    initialSearch={initialSearch}
    name="department_id"
    id="filter_department_id"
    type="departments"
    label={I18n.t('Explorer.AdvancedSearch.fields.departments.title')}
    helpUrl={I18n.t('Explorer.AdvancedSearch.help_uri')}
    helpTip={I18n.t('Explorer.AdvancedSearch.fields.departments.help')}
    analyticsEventPrefix="Advanced Search: Field"
    analyticsFieldName="Verified Department"
    loadingMessage={I18n.t('Explorer.AdvancedSearch.fields.departments.loading')}
    placeholder={I18n.t('Explorer.AdvancedSearch.fields.departments.placeholder')}
    queryMethod="departmentNames"
  />
);

DepartmentFilter.propTypes = {
  filterSet: PropTypes.instanceOf(FilterSet).isRequired,
  initialSearch: PropTypes.string
};

export default DepartmentFilter;
