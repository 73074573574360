import $ from 'jquery';

const setup = () => {
  window.addEventListener('DOMContentLoaded', () => {
    setupPasswordResetLink();
    setupMarketingPageLink();
    bindStateVisibility();
  });
};

const setupPasswordResetLink = () => {
  const targetLink = $('.password-reset-link a');
  targetLink.on('click', (e) => {
    e.preventDefault();
    window.Analytics.trackEvent('resetPassword');
    // Dont use $(e.target).attr('href'), it is broken when page is translated by Chrome
    window.location = targetLink.attr('href');
  });
};

const setupMarketingPageLink = () => {
  const targetLink = $('a[data-analytics="login-marketing-link"]');
  targetLink.on('click', () => {
    window.Analytics.trackEvent('Log In: Marketing Link Clicked');
  });
};

const bindStateVisibility = () => {
  const countrySelector = $('#user_country');
  const stateSelector = $('#user_state');
  const stateOption = stateSelector.parent();
  toggleState(countrySelector, stateSelector, stateOption);
  countrySelector.change(() => toggleState(countrySelector, stateSelector, stateOption));
};

const toggleState = (countrySelector, stateSelector, stateOption) => {
  if (countrySelector.val() == 'United States of America') {
    if (stateOption.hasClass('hidden')) stateOption.removeClass('hidden');
  } else {
    if (!stateOption.hasClass('hidden')) stateOption.addClass('hidden');
    stateSelector.val('');
  }
};

export { setup };
