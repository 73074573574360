const DOI_REGEX = /\b10\.\d{3,}\/\S+\b/;
const PMID_REGEX = /^\s*\d+\s*$/;
const NCT_ID_REGEX = /^\s*NCT\d+\s*$/i;
const ORCID_REGEX = /(\d{4}-\d{4}-\d{4}-\d{3}[\dx])/i;

import { compact, slice, trim } from 'lodash-es';

const Keyword = class {
  constructor(text) {
    this.id = 'keyword';
    this.text = I18n.htmlSafe('awesome_bar.types.keyword.result', { text });
    this.meta = '';
    this.filters = { q: text };
  }
};

const Title = class {
  constructor(text) {
    this.id = 'title';
    this.text = I18n.htmlSafe('awesome_bar.types.title.result', { text });
    this.meta = '';
    this.filters = { title: text };
  }
};

const Identifier = class {
  static fromString(string) {
    const potentialDOI = string.match(DOI_REGEX);
    if (potentialDOI && potentialDOI[0]) {
      return new Identifier({ type: 'dois', text: potentialDOI[0] });
    }

    const potentialPubmedID = string.match(PMID_REGEX);
    if (potentialPubmedID && potentialPubmedID[0]) {
      return new Identifier({ type: 'pmids', text: potentialPubmedID[0] });
    }

    const potentialNCTID = string.match(NCT_ID_REGEX);
    if (potentialNCTID && potentialNCTID[0]) {
      return new Identifier({ type: 'nct_ids', text: potentialNCTID[0] });
    }
  }

  constructor(result) {
    this.id = 'identifier';
    this.text = I18n.htmlSafe('awesome_bar.types.identifier.result', {
      type: this.translatedType(result.type),
      text: result.text
    });
    this.meta = '';
    this.filters = { identifier: trim(result.text) };
  }

  translatedType(type) {
    return I18n.t(type, { scope: 'awesome_bar.types.identifier.types' });
  }
};

const Author = class {
  constructor(result) {
    this.id = result.id;
    this.text = I18n.htmlSafe('awesome_bar.types.authors.result', {
      text: this.formatName(result)
    });
    this.meta = this.formatDepartments(result.departments);
    this.filters = { 'author_id[]': result.id };
  }

  formatDepartments(departments) {
    const totalCount = departments.length;
    const selected = slice(departments, 0, 2);
    const str = selected.join(', ');
    const hasTooManyDepartments = selected.length < totalCount - 1;

    if (hasTooManyDepartments) {
      return I18n.t('awesome_bar.author_departments_more', {
        departments: str,
        more: totalCount - selected.length
      });
    } else {
      return departments.join(', ');
    }
  }

  formatName(result) {
    return compact([result.first_name, result.last_name]).join(' ');
  }
};

const Department = class {
  constructor(result) {
    this.id = result.id;
    this.text = I18n.htmlSafe('awesome_bar.types.departments.result', {
      text: result.name
    });
    this.meta = '';
    this.filters = { 'department_id[]': result.id };
  }
};

const Publisher = class {
  constructor(result) {
    this.id = result.id;
    this.text = I18n.htmlSafe('awesome_bar.types.publishers.result', {
      text: result.name
    });
    this.meta = '';
    this.filters = { 'publisher_id[]': result.id };
  }
};

const Journal = class {
  constructor(result) {
    this.id = result.id;
    this.text = I18n.htmlSafe('awesome_bar.types.journals.result', {
      text: result.title
    });
    this.meta = this.formatISSNs(result.issns);
    this.filters = { 'journal_id[]': result.id };
  }

  formatISSNs(issns) {
    if (!issns.length) {
      return '';
    }
    return I18n.htmlSafe('awesome_bar.types.journals.meta', {
      issns: issns.join(', ')
    });
  }
};

const DoiPrefix = class {
  constructor(result) {
    this.id = result.id;
    this.text = I18n.htmlSafe('awesome_bar.types.doi_prefixes.result', {
      prefix: result.id
    });
    this.meta = '';
    this.filters = { 'doi_prefix[]': result.id };
  }
};

const OutputType = class {
  constructor(result) {
    const type_descriptor = I18n.t(result, {
      scope: 'awesome_bar.types.output_type.type'
    });

    this.id = result;
    this.text = I18n.htmlSafe('awesome_bar.types.output_type.result', {
      text: type_descriptor
    });
    this.meta = '';
    this.filters = { 'type[]': result };
  }
};

const More = class {
  constructor(type, count) {
    this.id = `${type}_more`;
    this.isLinkToMore = type;
    this.text = I18n.htmlSafe(type, {
      scope: 'awesome_bar.more_results',
      additional: count
    });
    this.meta = '';
    this.filters = {};
  }
};

const Orcid = class {
  static fromString(string) {
    const potentialOrcid = string.match(ORCID_REGEX);
    if (potentialOrcid && potentialOrcid[0]) {
      return new Orcid(potentialOrcid[0]);
    }
  }

  constructor(id) {
    this.id = id;
    this.text = I18n.htmlSafe('awesome_bar.types.orcid.result', {
      id: this.id
    });
    this.meta = '';
    this.filters = { orcid: this.id };
  }
};

const Affiliation = class {
  constructor(result) {
    this.id = result.id;
    this.text = I18n.htmlSafe('awesome_bar.types.affiliations.result', {
      text: result.name
    });
    this.meta = '';
    this.filters = { 'affiliations[]': result.id };
  }
};

const Funder = class {
  constructor(result) {
    this.id = result.id;
    this.text = I18n.htmlSafe('awesome_bar.types.funders.result', {
      text: result.name
    });
    this.meta = '';
    this.filters = { 'funders[]': result.id };
  }
};

const FieldOfResearch = class {
  constructor(result) {
    this.id = result.id;
    this.text = I18n.htmlSafe('awesome_bar.types.fields_of_research.result', {
      code: result.id,
      name: result.name
    });
    this.meta = '';
    this.filters = { 'field_of_research_codes[]': result.id };
  }
};

const SustainableDevelopmentGoal = class {
  constructor(result) {
    this.id = result.id;
    this.text = I18n.htmlSafe('awesome_bar.types.sustainable_development_goals.result', {
      code: result.id,
      name: result.name
    });
    this.meta = '';
    this.filters = { 'sustainable_development_goals[]': result.id };
  }
};

const Grant = class {
  constructor(result) {
    this.id = result.id;
    this.text = I18n.htmlSafe('awesome_bar.types.grants.result', {
      project_number: result.name,
      title: result.project_name
    });
    this.filters = { 'grant_id[]': result.id };
  }
};

export default {
  Keyword,
  Title,
  Identifier,
  Author,
  Department,
  Publisher,
  Journal,
  OutputType,
  More,
  Orcid,
  DoiPrefix,
  Affiliation,
  FieldOfResearch,
  Funder,
  SustainableDevelopmentGoal,
  Grant
};
