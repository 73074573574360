import React from 'react';
import PropTypes from 'prop-types';

import FilterSet from 'components/Explorer/models/filter_set';
import InputField from './InputField';

const DoiPrefixFilter = ({ filterSet, initialSearch }) => (
  <InputField
    filterSet={filterSet}
    initialSearch={initialSearch}
    name="doi_prefix"
    id="doi_prefix"
    type="doi_prefixes"
    label={I18n.t('Explorer.AdvancedSearch.fields.doi_prefix.name')}
    helpUrl={I18n.t('Explorer.AdvancedSearch.help_uri')}
    helpTip={I18n.t('Explorer.AdvancedSearch.fields.doi_prefix.help')}
    analyticsEventPrefix="Advanced Search: Field"
    analyticsFieldName="DOI Prefix"
    placeholder={I18n.t('Explorer.AdvancedSearch.fields.doi_prefix.placeholder')}
  />
);

DoiPrefixFilter.propTypes = {
  filterSet: PropTypes.instanceOf(FilterSet).isRequired,
  initialSearch: PropTypes.string
};

export default DoiPrefixFilter;
